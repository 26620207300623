import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, toDisplayString as _toDisplayString, unref as _unref, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-24d8aa0a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "package-page"
};
const _hoisted_2 = {
  class: "ws_table"
};
const _hoisted_3 = {
  class: "btn ws_mb20"
};
import { onMounted, ref, watch, onUnmounted } from 'vue';
import OperationButtonList from 'common/components/operation-button-list';
import PackageForm from './components/package-form.vue';
import { queryPackagePage, deletePackageForm, publishPackageForm } from '@/api';
import { ElMessage, ElMessageBox } from 'element-plus';
import { CODE_ENUM, PAGE_SIZES, PACKAGE_PUBLISH_STATUS_CODE, transformNumber } from 'common';
// import moment from 'moment';

export default {
  __name: 'index',
  setup(__props) {
    const statusTextMap = {
      [PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_PUBLISH]: '已发布',
      [PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_CANCLE]: '未发布'
    };
    const statusTagMap = {
      [PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_PUBLISH]: 'success',
      [PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_CANCLE]: 'warning'
    };
    const formConf = ref({
      visible: false,
      data: {}
    });
    const handleForm = (data = {}) => {
      formConf.value = {
        visible: true,
        data
      };
    };
    const handleDelete = row => {
      ElMessageBox.confirm('你确定要删除这个套餐吗?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        autofocus: false
      }).then(() => {
        toDelete(row);
      });
    };
    const toDelete = async ({
      id
    }) => {
      const res = await deletePackageForm({
        id
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        ElMessage.success('删除成功');
        fetchList();
      }
    };
    const handleCHangeStatus = async ({
      id,
      stateCode
    }) => {
      const publishCode = stateCode === PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_PUBLISH ? PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_CANCLE : PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_PUBLISH;
      const res = await publishPackageForm({
        id,
        publishCode
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        ElMessage.success(res === null || res === void 0 ? void 0 : res.msg);
        fetchList();
      }
    };
    const getButtonList = ({
      stateCode
    }) => {
      if (stateCode === PACKAGE_PUBLISH_STATUS_CODE.PACKAGE_PUBLISH) {
        return [{
          text: '撤销发布',
          onClick: handleCHangeStatus
        }];
      } else {
        return [{
          text: '编辑',
          onClick: handleForm
        }, {
          text: '发布',
          onClick: handleCHangeStatus
        }, {
          text: '删除',
          onClick: handleDelete
        }];
      }
    };
    const tableData = ref([]);
    const pageParams = ref({
      curPage: 1,
      pageSize: 10
    });
    const total = ref(0);
    const handleSizeChange = () => {
      pageParams.value.curPage = 1;
      fetchList();
    };
    const handleCurrentChange = () => {
      fetchList();
    };
    const col = [{
      prop: 'name',
      label: '套餐名称'
    }, {
      prop: 'broadbandTypeValue',
      label: '带宽'
    }, {
      prop: 'shortMsgTotal',
      label: '短报文数量'
    }, {
      prop: 'chargModeValue',
      label: '计费模式'
    }, {
      prop: 'price',
      label: '套餐价格(元)',
      className: 'align-right',
      formatter: ({
        price
      }) => {
        return transformNumber(price);
      }
    }];
    const tableLoading = ref(false);
    const fetchList = async () => {
      tableLoading.value = true;
      const res = await queryPackagePage({
        ...pageParams.value
      }).finally(() => {
        tableLoading.value = false;
      });
      if ((res === null || res === void 0 ? void 0 : res.code) === CODE_ENUM.SUCCESS) {
        var _res$data, _res$data2;
        tableData.value = (res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.records) || [];
        total.value = (res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.total) || 0;
      }
    };
    fetchList();
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_empty = _resolveComponent("el-empty");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
        type: "primary",
        onClick: handleForm
      }, {
        default: _withCtx(() => [_createTextVNode("添加套餐")]),
        _: 1
      })]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "table",
        data: tableData.value
      }, {
        empty: _withCtx(() => [_createVNode(_component_el_empty, {
          description: "暂无数据"
        })]),
        default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(col, (item, index) => {
          return _createVNode(_component_el_table_column, _mergeProps({
            key: index,
            ref_for: true
          }, {
            ...item
          }, {
            "show-overflow-tooltip": ""
          }), null, 16);
        }), 64)), _createVNode(_component_el_table_column, {
          label: "状态",
          width: "160"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_tag, {
            type: statusTagMap[scope.row.stateCode]
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(statusTextMap[scope.row.stateCode]), 1)]),
            _: 2
          }, 1032, ["type"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          width: "240"
        }, {
          default: _withCtx(scope => [_createVNode(_unref(OperationButtonList), {
            buttonList: getButtonList(scope.row),
            scope: scope.row
          }, null, 8, ["buttonList", "scope"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, tableLoading.value]]), _createVNode(_component_el_pagination, {
        class: "ws_mt50",
        "current-page": pageParams.value.curPage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => pageParams.value.curPage = $event),
        "page-size": pageParams.value.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => pageParams.value.pageSize = $event),
        "page-sizes": _unref(PAGE_SIZES),
        layout: "->, total, sizes, prev, pager, next, jumper ",
        total: total.value,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["current-page", "page-size", "page-sizes", "total"])])]), _createVNode(PackageForm, {
        visible: formConf.value.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = $event => formConf.value.visible = $event),
        data: formConf.value.data,
        onSuccess: fetchList
      }, null, 8, ["visible", "data"])], 64);
    };
  }
};